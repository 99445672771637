export default class CardObject {
  public percentage: string;

  public title: string;

  public description: string;

  public code: string;

  public link: string;

  constructor(
    percentage: string,
    title: string,
    description: string,
    code: string,
    link = '',
  ) {
    this.percentage = percentage;
    this.title = title;
    this.description = description;
    this.code = code;
    this.link = link;
  }
}
