













import {
  Component, Prop, Watch,
} from 'vue-property-decorator';
import CatObject from '@/objects/CatObject';
import VueExtension from '../../VueExtension';
import Mbc from './Mbc.vue';
import TitleElement from '../Text/TitleElement.vue';
import MundoBancanteCardObject from '../../objects/MundoBancanteCardObject';
import Api from '../../helpers/Api';

@Component({
  components: {
    Mbc,
    TitleElement,
  },
})
export default class MundoBancantecards extends VueExtension {
    @Prop() private catObject!: CatObject;

    private publicPath = process.env.BASE_URL;

    public cards: Array<MundoBancanteCardObject> = [
      // new MundoBancanteCardObject(`${this.publicPath}img/c.png`, 'Conseguí tu NFT', 'Vinculá tu wallet', 'https://elgatoylacaja.com/bancar/mundobancante/wallet', '_self'),
      new MundoBancanteCardObject(`${this.publicPath}img/b.png`, 'Sumate a Slack', 'Y conocé a la comunidad de bancantes', 'https://join.slack.com/t/eglcbancantes/shared_invite/zt-egzfa0ki-aJMMz1wZKelNiXOD0~x9yw'),
    ]

    @Watch('catObject') onCatObject() {
      if (!(this.catObject.catColors && this.catObject.catColors.length)) return;
      this.cards.push(
        new MundoBancanteCardObject(
          'https://cdn.elgatoylacaja.com/2021/05/d.png',
          'Renová tu gatito',
          'Tu gatito virtual ahora en 3D. Elegí el que más te guste, que color es, y si tiene anteojos o piercings.  ',
          'https://elgatoylacaja.com/adoptar',
          '_self',
        ),
      );
    }

    created() {
      this.getGiftsCard();
    }

    async getGiftsCard() {
      const token = this.$store.state.accessToken;

      try {
        const bodyFormData = new FormData();
        bodyFormData.append('creator_id', '1');

        const override = localStorage.getItem('mundobancante-override');
        if (override) bodyFormData.append('override', override);

        const response = await fetch(`${Api.giftGet}`, {
          method: 'POST',
          headers: { token: `Bearer ${token}` },
          body: bodyFormData,
        });

        if (!response.ok) {
          throw new Error('Network response was not ok');
        }

        const data = await response.json();
        const pendingGifts = data.filter((x: { order_id: string }) => !x.order_id);

        let giftCardTitle = 'Regalos';
        let giftCardDescription = 'Mirá el detalle de tus regalos';

        if (pendingGifts.length > 0) {
          giftCardTitle = `Hay ${pendingGifts.length} ${pendingGifts.length === 1 ? 'regalo' : 'regalos'} para vos`;
          giftCardDescription = `Entrá acá para que te ${pendingGifts.length === 1 ? 'lo' : 'los'} enviemos`;
        }

        this.cards.push(
          new MundoBancanteCardObject(
            `${this.publicPath}img/a.png`,
            giftCardTitle,
            giftCardDescription,
            'https://elgatoylacaja.com/bancar/mundobancante/gifts',
            '_self',
          ),
        );
      } catch (e) {
        console.error(e);
      }
    }
}
