export default class CardObject {
  public img: string;

  public title: string;

  public description: string;

  public link: string;

  public target: string;

  constructor(
    img: string,
    title: string,
    description: string,
    link: string,
    target = '_blank',
  ) {
    this.img = img;
    this.title = title;
    this.description = description;
    this.link = link;
    this.target = target;
  }
}
