





























import { Component, Prop } from 'vue-property-decorator';
import VueExtension from '../../VueExtension';
import Telar from '../../assets/svg/telar.svg';
import IconCatOn from '../../assets/svg/icon-cat-on.svg';
import IconCatOff from '../../assets/svg/icon-cat-off.svg';
import Api from '../../helpers/Api';

@Component({
  components: {
    Telar,
    IconCatOn,
    IconCatOff,
  },
})

export default class MundoBancanteTelarCard extends VueExtension {
  private copied = false;

  private publicPath = process.env.BASE_URL;

  @Prop() private refCode!: string;

  private catsQty = ['', '', '', '', '', '', ''];

  private referrals = 0;

  get link() {
    return `elgatoylacaja.com/bancar?r=${encodeURIComponent(this.refCode)}`;
  }

  async mounted() {
    const token = this.$store.state.accessToken;

    try {
      const bodyFormData = new FormData();
      bodyFormData.append('creator_id', '1');

      const override = localStorage.getItem('mundobancante-override');
      if (override) bodyFormData.append('override', override);

      const response = await fetch(`${Api.getReferrals}`,
        {
          method: 'POST',
          headers: { token: `Bearer ${token}` },
          body: bodyFormData,
        });

      const data = await response.json();

      this.referrals = data.referrals;
    } catch (e) {
      console.log(e);
    }
  }

  copyCode($event: any) {
    $event.preventDefault();
    navigator.clipboard.writeText(this.link).then(() => {
      this.copied = true;
      setTimeout(() => {
        this.copied = false;
      }, 1000);
    });
  }
}
