















import { Component, Vue } from 'vue-property-decorator';
import { mapState } from 'vuex';
import Mbdc from './Mbdc.vue';
import TitleElement from '../Text/TitleElement.vue';
import DiscountCardObject from '../../objects/DiscountCardObject';
import Api from '../../helpers/Api';

@Component({
  components: {
    Mbdc,
    TitleElement,
  },
  computed: mapState(['accessToken']),
})
export default class MundoBancantediscountcards extends Vue {
    public accessToken!: string;

    public cards: Array<DiscountCardObject> = [];

    async created() {
      const token = this.accessToken;

      const bodyFormData = new FormData();
      bodyFormData.append('creator_id', '1');

      const override = localStorage.getItem('mundobancante-override');
      if (override) bodyFormData.append('override', override);

      const json = await fetch(`${Api.discountGet}`, {
        method: 'POST',
        body: bodyFormData,
        headers: { token: `Bearer ${token}` },
      });

      if (!json.ok) {
        throw new Error('Network response was not ok');
      }

      const data = await json.json();

      [...data].sort((a, b) => a.order - b.order).forEach((x) => {
        this.cards.push(new DiscountCardObject(
          `${x.discount}`,
          x.title,
          x.description,
          x.code,
          x.link,
        ));
      });
    }
}
