import { render, staticRenderFns } from "./CatProfileimg.vue?vue&type=template&id=5b96d086&scoped=true&"
import script from "./CatProfileimg.vue?vue&type=script&lang=ts&"
export * from "./CatProfileimg.vue?vue&type=script&lang=ts&"
import style0 from "./CatProfileimg.vue?vue&type=style&index=0&id=5b96d086&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "5b96d086",
  null
  
)

export default component.exports