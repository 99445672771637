







import { Component, Vue, Prop } from 'vue-property-decorator';
import TextMedium from '../Text/TextMedium.vue';
import Mbtc from './Mbtc.vue';
import TitleElement from '../Text/TitleElement.vue';
import QuestionCircle from '../../assets/svg/question-circle.svg';
import Envelope from '../../assets/svg/enve.svg';
import ArrowRight from '../../assets/svg/arrow-right.svg';

@Component({
  components: {
    TextMedium,
    TitleElement,
    QuestionCircle,
    Envelope,
    ArrowRight,
    Mbtc,
  },
})
export default class MundoBancanteTelar extends Vue {
  private copied = false;

  private publicPath = process.env.BASE_URL;

  @Prop() private refCode!: string;
}
