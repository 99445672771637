




















import { Component, Vue } from 'vue-property-decorator';
import CatObject from '@/objects/CatObject';
import MundoBancanteHeaderwithcat from '../components/MundoBancante/MundoBancanteHeaderwithcat.vue';
import MundoBancantecards from '../components/MundoBancante/MundoBancantecards.vue';
import MundoBancantedoubts from '../components/MundoBancante/MundoBancantedoubts.vue';
import MundoBancantetelar from '../components/MundoBancante/MundoBancantetelar.vue';
import MundoBancantediscountcards from '../components/MundoBancante/MundoBancantediscountcards.vue';
import TextMedium from '../components/Text/TextMedium.vue';
import Api from '../helpers/Api';

@Component({
  components: {
    MundoBancanteHeaderwithcat,
    TextMedium,
    MundoBancantecards,
    MundoBancantediscountcards,
    MundoBancantedoubts,
    MundoBancantetelar,
  },
})
export default class MundoBancante extends Vue {
  private logged = false;

  private refCode = '';

  private catObject = new CatObject();

  onCatCreated(cat: CatObject) {
    this.catObject = cat;
  }

  async created() {
    const token = this.$store.state.accessToken;

    const bodyFormData = new FormData();
    bodyFormData.append('creator_id', '1');

    const override = localStorage.getItem('mundobancante-override');
    if (override) bodyFormData.append('override', override);

    try {
      const response = await fetch(`${Api.subscriptionGet}`, {
        method: 'POST',
        body: bodyFormData,
        headers: { token: `Bearer ${token}` },
      });

      if (!response.ok) {
        throw new Error('Network response was not ok');
      }

      const data = await response.json();

      if (override === data.payer_email) {
        this.$store.commit('setOverride', override);
      }

      const userDataResponse = await fetch(`${Api.userDataGet}`, {
        method: 'POST',
        body: bodyFormData,
        headers: { token: `Bearer ${token}` },
      });

      if (userDataResponse.status === 404) this.$router.push({ name: 'Userdata' });
      else {
        this.logged = true;
        this.refCode = data.ref_code;
      }
    } catch (e) {
      console.error(e);
      this.$router.push({ name: 'Home' });
    }
  }
}
