
























import { Component, Vue, Prop } from 'vue-property-decorator';
import DiscountCardObject from '../../objects/DiscountCardObject';

@Component({
  components: {
  },
})
export default class MundoBancanteDiscountCards extends Vue {
  @Prop() private cardObject!: DiscountCardObject;

  private copied = false;

  private publicPath = process.env.BASE_URL;

  copyCode($event: any) {
    if (this.cardObject.link === 'false') {
      return;
    }

    if (this.cardObject.link && this.cardObject.link !== 'false') {
      window.open(this.cardObject.link, '_blank');
      return;
    }

    $event.preventDefault();
    navigator.clipboard.writeText(this.cardObject.code).then(() => {
      this.copied = true;
      setTimeout(() => {
        this.copied = false;
      }, 1000);
    });
  }
}
