


























import { Component } from 'vue-property-decorator';
import { mapState } from 'vuex';
import CatObject from '@/objects/CatObject';
import VueExtension from '../../VueExtension';
import TitleDisplay from '../Text/TitleDisplay.vue';
import TitleElement from '../Text/TitleElement.vue';
import LinkSecondarySmall from '../Button/LinkSecondarySmall.vue';
import CatProfileimg from './CatProfileimg.vue';
import ChevronLeft from '../../assets/svg/chevron-left.svg';
import BancanteWorldTitle from '../../assets/svg/bancanteworld-title.svg';
import Api from '../../helpers/Api';

@Component({
  components: {
    TitleDisplay,
    TitleElement,
    LinkSecondarySmall,
    ChevronLeft,
    BancanteWorldTitle,
    CatProfileimg,
  },
  computed: mapState(['username', 'accessToken', 'override']),
})
export default class MundoBancanteHeaderwithcat extends VueExtension {
  public accessToken!: string;

  public username!: string;

  public override!: string;

  private count = 0;

  onCatCreated(cat: CatObject) {
    this.$emit('catcreated', cat);
  }

  async created() {
    try {
      const token = this.$store.state.accessToken;
      const bodyFormData = new FormData();
      bodyFormData.append('creator_id', '1');

      const override = localStorage.getItem('mundobancante-override');
      if (override) bodyFormData.append('override', override);

      const response = await fetch(`${Api.subscriptionCount}`, {
        method: 'POST',
        headers: { token: `Bearer ${this.accessToken}` },
        body: bodyFormData,
      });

      if (!response.ok) {
        throw new Error('Network response was not ok');
      }

      const data = await response.json();

      this.count = data.count;
    } catch (e) {
      console.error(e);
      this.$router.push({ name: 'Redirect' });
    }
  }

  goBack = () => {
    window.location.href = 'https://elgatoylacaja.com/perfil';
  }
}
