






























































import Dayjs from 'dayjs';
import { Component } from 'vue-property-decorator';
import { mapState } from 'vuex';
import TextMedium from '../Text/TextMedium.vue';
import Mbtc from './Mbtc.vue';
import TitleElement from '../Text/TitleElement.vue';
import QuestionCircle from '../../assets/svg/question-circle.svg';
import Envelope from '../../assets/svg/enve.svg';
import ArrowRight from '../../assets/svg/arrow-right.svg';
import VueExtension from '../../VueExtension';
import Api from '../../helpers/Api';
import Config from '../../helpers/Config';
import montoValidation from '../../utils/validations';

@Component({
  components: {
    TextMedium,
    TitleElement,
    QuestionCircle,
    Envelope,
    ArrowRight,
    Mbtc,
  },
  computed: mapState(['accessToken']),
})
export default class MundoBancanteMonto extends VueExtension {
  public dayjs = Dayjs;

  public accessToken!: string;

  private monto = '';

  private payment = '...';

  private montoActual = '...';

  private dateCreated = '';

  private error = '';

  private buttonText = 'Guardar';

  private hasAnnouncementGift = true;

  private specialGiftAmount = Config.SpecialGiftAmount;

  private specialGiftAmountUSD = Config.SpecialGiftAmountUSD;

  private allowedToEdit = false;

  private inflationAdjusted = false;

  private originalInflationAdjusted = false;

  private sending = false;

  private el!: HTMLInputElement;

  private widthMachine!: HTMLSpanElement;

  async onSubmit($event: any) {
    $event.preventDefault();
    if (this.sending) return;
    const montoVal = montoValidation(Number(this.monto), this.payment);
    if (!montoVal.status) {
      this.error = montoVal.message;
      this.sending = false;
      this.buttonText = 'Guardar';
      return;
    }

    const token = this.accessToken;
    const bodyFormData = new FormData();
    bodyFormData.append('creator_id', '1');
    bodyFormData.append('monto', this.monto);
    bodyFormData.append('inflation_adjusted', this.inflationAdjusted ? '1' : '0');

    const override = localStorage.getItem('mundobancante-override');
    if (override) bodyFormData.append('override', override);

    this.error = '';
    this.buttonText = 'Cargando...';

    try {
      this.sending = true;
      const response = await fetch(`${Api.sendMontoMail}`, {
        method: 'POST',
        body: bodyFormData,
        headers: { token: `Bearer ${token}` },
      });

      if (response.ok) {
        const data = await response.json();

        const hasGift = (
          !this.hasAnnouncementGift
                && (

                  (
                    this.payment !== 'paypal'
                  && +this.montoActual < this.specialGiftAmount
                  && +this.monto >= this.specialGiftAmount
                  )
                  || (
                    this.payment === 'paypal'
                  && +this.montoActual < this.specialGiftAmountUSD
                  && +this.monto >= this.specialGiftAmountUSD
                  )

                )
        );

        if (data.result === 'success') {
          this.montoActual = this.monto;
          this.originalInflationAdjusted = this.inflationAdjusted;
          this.sending = false;
          this.allowedToEdit = false;

          if (hasGift) {
            this.$router.push(
              {
                name: 'Montocambiado',
                query: {
                  gift: 'true',
                },
              },
            );
          }
        }
      } else {
        this.sending = false;
        this.buttonText = 'Guardar';
        const data = await response.json();
        if (!data) throw new Error('Network response was not ok');
        this.error = `${data.messages.error}`;
        if (this.error.indexOf('monto válido') === -1 && this.error.indexOf('cambió') === -1) {
          this.error = `${this.error}. Contactanos para revisarlo manualmente escribiendo a: bancan@elgatoylacaja.com`;
        }
      }

      this.sending = false;
      this.buttonText = 'Guardar';
    } catch (e) {
      console.error(e);
      this.buttonText = 'Enviar';
      this.error = 'Ocurrió un error en el servidor. Por favor envianos un mail a estamosaprendiendo@elgatoylacaja.com para que podamos arreglarlo.';
      this.sending = false;
    }
  }

  cancel($event: any) {
    $event.preventDefault();
    this.allowedToEdit = false;
    this.monto = this.montoActual;
    this.inflationAdjusted = this.originalInflationAdjusted;
    this.error = '';
    setTimeout(() => this.onkeyup(), 100);
  }

  edit($event: any) {
    $event.preventDefault();
    this.allowedToEdit = true;

    setTimeout(() => {
      if (this.$refs.montoInput) {
        const input = this.$refs.montoInput as HTMLInputElement;
        input.focus();
      }
    }, 100);
  }

  async created() {
    const token = this.accessToken;

    const bodyFormData = new FormData();
    bodyFormData.append('creator_id', '1');

    const override = localStorage.getItem('mundobancante-override');
    if (override) bodyFormData.append('override', override);

    try {
      const [subsDataResponse, giftGetResponse] = await Promise.all([
        fetch(`${Api.subscriptionGet}`, {
          method: 'POST',
          body: bodyFormData,
          headers: { token: `Bearer ${token}` },
        }),
        fetch(`${Api.giftGet}`, {
          method: 'POST',
          body: bodyFormData,
          headers: { token: `Bearer ${token}` },
        }),
      ]);

      if (!subsDataResponse.ok || !giftGetResponse.ok) {
        throw new Error('Network response was not ok');
      }

      const [subsData, giftGet] = await Promise.all([
        subsDataResponse.json(),
        giftGetResponse.json(),
      ]);

      this.dateCreated = subsData.date;
      this.montoActual = subsData.transaction_amount;
      this.monto = subsData.transaction_amount;
      this.payment = subsData.payment_provider;
      this.inflationAdjusted = subsData.inflation_adjusted;
      this.originalInflationAdjusted = subsData.inflation_adjusted;

      this.hasAnnouncementGift = !!giftGet.find((x: any) => x.name === 'Libro de regalo');
    } catch (e) {
      console.error(e);
      this.$router.push({ name: 'Home' });
    }

    this.el = document.querySelector('.input-wrap .inpt') as HTMLInputElement;
    this.widthMachine = document.querySelector('.input-wrap .width-machine') as HTMLSpanElement;
    if (this.el) {
      this.el.addEventListener('keyup', this.onkeyup);
    }
  }

  beforeUnmount() {
    this.el.removeEventListener('keyup', this.onkeyup);
  }

  onkeyup() {
    if (this.widthMachine) this.widthMachine.innerHTML = this.el.value;
  }
}
