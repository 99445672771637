










import { Component } from 'vue-property-decorator';
import VueExtension from '../../VueExtension';
import Api from '../../helpers/Api';
import CatObject from '../../objects/CatObject';
import Cat from '../Cat.vue';

@Component({
  components: {
    Cat,
  },
})
export default class CatProfileimg extends VueExtension {
    private cat = new CatObject();

    private loaded = false;

    created() {
      this.getCats();
    }

    async getCats() {
      try {
        const token = this.$store.state.accessToken;

        const bodyFormData1 = new FormData();
        bodyFormData1.append('key', 'gatito3D');
        bodyFormData1.append('creator_id', '1');

        const override = localStorage.getItem('mundobancante-override');
        if (override) bodyFormData1.append('override', override);

        const bodyFormData2 = new FormData();
        bodyFormData2.append('key', 'gato');
        bodyFormData2.append('creator_id', '1');

        if (override) bodyFormData2.append('override', override);

        let response = null;

        response = await fetch(`${Api.extradata}`, {
          method: 'POST',
          body: bodyFormData1,
          headers: { token: `Bearer ${token}` },
        });

        if (!response.ok) {
          response = await fetch(`${Api.extradata}`, {
            method: 'POST',
            body: bodyFormData2,
            headers: { token: `Bearer ${token}` },
          });
        }

        if (!response.ok) {
          throw new Error('Network response was not ok');
        }

        const jsonData = await response.json();

        const data = JSON.parse(jsonData.data);
        const catObject = new CatObject();
        catObject.name = data.name;
        catObject.description = data.description;
        catObject.ig = data.ig;
        catObject.tw = data.tw;
        catObject.background = data.background;
        catObject.catId = data.catId;
        catObject.catColors = data.catColors;
        catObject.base64 = data.base64;

        this.cat = catObject;

        this.loaded = true;

        this.$emit('catcreated', catObject);
      } catch (e) {
        console.error(e);
      }
    }
}
