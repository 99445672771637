













import { Component, Vue, Prop } from 'vue-property-decorator';
import MundoBancanteCardObject from '../../objects/MundoBancanteCardObject';
import ArrowButton from '../../assets/svg/arrow-button.svg';

@Component({
  components: {
    ArrowButton,
  },
})
export default class MundoBancantecards extends Vue {
  @Prop() private cardObject!: MundoBancanteCardObject;
}
