




















































import { Component, Vue } from 'vue-property-decorator';
import TextMedium from '../Text/TextMedium.vue';
import TitleElement from '../Text/TitleElement.vue';
import QuestionCircle from '../../assets/svg/question-circle.svg';
import Envelope from '../../assets/svg/enve.svg';
import ArrowRight from '../../assets/svg/arrow-right.svg';
import MundoBancantemonto from './MundoBancantemonto.vue';

@Component({
  components: {
    TextMedium,
    TitleElement,
    QuestionCircle,
    Envelope,
    ArrowRight,
    MundoBancantemonto,
  },
})
export default class MundoBancanteDoubts extends Vue {
  private copied = false;

  private publicPath = process.env.BASE_URL;
}
